body {
	margin: 0;
}

div.root {
	display: flex;
	flex-wrap: wrap;
}

div.root .main {
	display: flex;
	width: 100%;
	justify-content: flex-start;
	flex-wrap: wrap;
	line-height: normal;
	/* some bs */
}

.navbar {
	width: 100%;
	background: rgba(255,255,255,0.96) !important;
	/* position: fixed; */
	height: 76px;
	padding: 0px;
	box-shadow: 0 0 6px rgba(0,0,0,0.2);
}

.navbar-brand {
	margin-right: 0px;
}

.navbar .left_container {
	display: flex;
	justify-content: flex-start;
	margin: 6px 20px 6px 12px;
	align-items: center;
/*	width: 270px;*/
}
.nav_bar .left_container .aaaid_logo {
	margin: 12px 0 0 12px;
	text-decoration: none;
	outline: none;
}
.nav_bar .left_container .aaaid_logo img {
	vertical-align: middle;
}
.navbar span.navbar-text {
	color: #666;
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	font-size: 24px;
	margin-left: 15px;
	vertical-align: middle;
}

.navbar-nav {
	align-items: center;
}

.navbar .nav-item a
{
	font-size: 20px;
	margin: 12px 0px 12px 26px;
	color: #666 !important;
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
	padding: 0px !important;
}

.nav_bar .right_container .hamburgerbut {
	margin-right: 25px;
	margin-top: 25px;
	width: 40px;
	height: 26px;
}

.nav_bar .right_container .hamburgerbut .hamimg {
	outline: none;
	border-style: none;
	outline-width: 0;
	background: url('../images/HamburgerButton.png') no-repeat;
	background-size: 100%;
	width: 40px;
	height: 26px;
	cursor: pointer;
}

.nav_bar .right_container .hamburgerbut .scrollable-menu-ham {
	display: none;
	position: absolute;
	background-color: #f9f9f9;
	height: auto;
	max-height: 300px;
	width: 325px;
	overflow-x: hidden;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.nav_bar .right_container .hamburgerbut .scrollable-menu-ham:hover {
	cursor: pointer;
}
.nav_bar .right_container .hamburgerbut .scrollable-menu-ham option {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	font-family: 'Roboto', sans-serif;
	font-weight: 200;
	font-size: 26px;
	display: block;
	border-bottom: 1px solid #999;
}
.nav_bar .right_container .hamburgerbut .scrollable-menu-ham option:hover {
	color: #fff;
	background-color: #606060;
}
.nav_bar .right_container .hamburgerbut .show {
	display: block;
	right: 30px;
	width: 200px;
}

.top_sec {
	background: white;
	width: 100%;
	padding: 0 10% 60px;
	margin-top: 76px;
	box-sizing: content-box;
}

.top_sec *:not(button, select) {
	box-sizing: content-box;
}

.home .top_sec {
	background: url('../images/background_right.png');
	background-position: bottom right;
	background-repeat: no-repeat;
	background-color: #f3f3f3;
	background-size: contain;
	height: 500px;
}

.top_sec .text,
.middle_sec .text {
	display: block;
	width: 100%;
	/* text-align: center; */
}

h1,
.top_sec .text .header_top,
.middle_sec .text .header_top {
	margin: 30px 0 0;
	color: #666;
	font-family: 'Roboto', sans-serif;
	font-size: 30px;
	height: auto;
	font-weight: bold;
}
.home .top_sec .text .header_top,
.middle_sec .text .header_top {
	margin-top: 60px;
	color: #333;
	font-size: 36px;
}

.top_sec .text .header_line,
.middle_sec .text .header_line {
	margin: 0 auto;
	padding-top: 20px;
	width: 28px;
	border-bottom: 1px solid #3f3f3f;
}
.home .top_sec .text .header_line,
.middle_sec .text .header_line {
	border-color: #fff;
}

.top_sec .text .header_bottom,
.middle_sec .text .header_bottom {
	margin: 0;
	padding-top: 29px;
	color: #333;
	font-family: 'Roboto', sans-serif;
	font-size: 24px;
}
.home .top_sec .text .header_bottom,
.middle_sec .text .header_bottom {
	padding-top: 40px;
	color: #333;
	font-weight: normal;
}

.top_sec .drop_down_container {
/*	display: flex;
	position: relative;
	justify-content: center;*/
	margin-top: 20px;
/*	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	font-size: 15px;*/
}
.top_sec .drop_down_container .drop_down_img {
	-webkit-appearance: none;
	appearance: none;
	z-index: 1;
	outline: none;
	border-style: none;
	outline-width: 0;
	width: 250px;
	height: 50px;
	background: rgba(243,243,243,0.5);
	color: #333;
	font-size: 24px;
	white-space: nowrap;
	cursor: pointer;
	border: 2px solid #02aaea;
	border-radius: 5px;
	box-sizing: border-box;
	position: relative;
	padding: 0 36px 0 12px;
	text-align: left;
}
.top_sec .drop_down_container .drop_down {
	display: flex;
	align-items: center;
	max-width: 250px;
	/* justify-content: center; */
}
.top_sec .drop_down_container .after {
	background-image: url(../images/country_arrow.png);
	height: 5px;
	width: 10px;
	position: relative;
	left: -24px;
	outline: none;
	border-style: none;
	outline-width: 0;
	cursor: pointer;
	z-index: 0;
}
.top_sec .drop_down_container .drop_down .arrow .show {
	display: block;
}
.top_sec .drop_down_container .drop_down .arrow:hover {
	cursor: pointer;
}
.top_sec .drop_down_container .scrollable-menu {
	display: none;
	margin-top: 46px;
	position: absolute;
	background-color: #f9f9f9;
	height: auto;
	max-height: 300px;
	width: 325px;
	overflow-x: hidden;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.top_sec .drop_down_container .scrollable-menu:hover {
	cursor: pointer;
}
.top_sec .drop_down_container .scrollable-menu option {
	color: black;
	padding: 12px 16px;
	text-decoration: none;
	font-family: 'Roboto', sans-serif;
	font-weight: 200;
	font-size: 26px;
	display: block;
	border-bottom: 1px solid #999;
}
.top_sec .drop_down_container .scrollable-menu option:hover {
	color: #fff;
	background-color: #606060;
}
.top_sec .drop_down_container .show {
	display: block;
}

.top_sec button.photo_img,
.top_sec .done button,
.top_sec .home_button .return_home {
	/* margin: 0 auto; */
	display: block;
	color: white;
	background-color: #02aaea;
	height: 50px;
	font-size: 20px;
	border-radius: 5px;
	border: none;
	margin-top: 40px;
	padding: 0 40px;
	text-align: center;
	outline: none;
	white-space: nowrap;
	cursor: pointer;
}
.top_sec button.photo_img:disabled,
.top_sec button.photo_img[disabled] {
	background-color: #999;
}

.top_sec .box_steps {
	padding-top: 30px;
	display: flex;
	justify-content: center;
	width: 100%;
}
.top_sec .box_steps .step1,
.top_sec .box_steps .step2 {
	margin-right: 32px;
}

.top_sec .bubbles {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.top_sec .bubbles ul {
	padding: 40px 0 0;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-between;
	height: 350px;
	width: 35%;
	margin: 0 0 0 7%;
	list-style: none;
}

.top_sec .bubbles ul li {
	height: 123px;
}

.top_sec .bubbles ul li .text_container {
	height: 119px;
	width: 119px;
	top: -123px;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.top_sec .bubbles ul li .text_container .inside_header {
	width: 110px;
	position: relative;
	flex-wrap: wrap;
	font-size: 16px;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	text-align: center;
	color: #fff;
}

.top_sec .bubbles ul li .text_container .inside_text {
	width: 110px;
	position: relative;
	flex-wrap: wrap;
	font-size: 14px;
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	text-align: center;
	color: #fff;
}

.top_sec .bubbles .phone_photo {
	padding-top: 147px;
}

.top_sec .step1_checkpoint,
.top_sec .step2_checkpoint,
.top_sec .step3_checkpoint {
	display: flex;
	justify-content: center;
}

.top_sec .step1_checkpoint img:not(.ball),
.top_sec .step2_checkpoint img:not(.ball),
.top_sec .step3_checkpoint img:not(.ball) {
	margin-left: 18px;
}
.top_sec .step1_checkpoint img,
.top_sec .step3_checkpoint img {
	margin-top: 50px;
}


.top_sec .avatar {
	display: flex;
	justify-content: center;
	padding-top: 50px;
}

.top_sec .avatar .avatar_pic {
	display: flex;
	justify-content: flex-start;
}
.top_sec .avatar .avatar_pic .img0 {
	width: 211px;
	/* height: 265px; */
}

.top_sec .avatar .avatar_pic .B0 {
	width: 89px;
	height: 50px;
	position: absolute;
	z-index: 10;
}

.top_sec .box_container {
	display: flex;
	justify-content: center;
	margin-top: 44px;
}

.top_sec .box_container .nested_box_container {
	display: flex;
	justify-content: center;
	position: absolute;
	width: 100%;
	/* height: 66px; */
	z-index: 10;
}
.top_sec .box_container .nested_box_container .B1,
.top_sec .box_container .nested_box_container .B2,
.top_sec .box_container .nested_box_container .B3,
.top_sec .box_container .nested_box_container .B4,
.top_sec .box_container .nested_box_container .B5 {
	width: 89px;
	height: 50px;
}
.top_sec .box_container .nested_box_container .B1 {
	margin-right: 12px;
}
.top_sec .box_container .nested_box_container .B2,
.top_sec .box_container .nested_box_container .B3,
.top_sec .box_container .nested_box_container .B4 {
	margin-right: 5px;
}

.top_sec .avatar .avatar_pic .B0 .nested_img0,
.top_sec .box_container .nested_box_container .B1 .nested_img1,
.top_sec .box_container .nested_box_container .B2 .nested_img2,
.top_sec .box_container .nested_box_container .B3 .nested_img3,
.top_sec .box_container .nested_box_container .B4 .nested_img4,
.top_sec .box_container .nested_box_container .B5 .nested_img5 {
	display: none;
	outline: none;
	border-style: none;
	outline-width: 0;
	width: 16px;
	height: 16px;
}

.top_sec .box_container .A1,
.top_sec .box_container .A2,
.top_sec .box_container .A3,
.top_sec .box_container .A4 {
	width: 89px;
	margin-right: 5px;
}

.top_sec .box_container .A2 .nested_img2,
.top_sec .box_container .A3 .nested_img3,
.top_sec .box_container .A4 .nested_img4,
.top_sec .box_container .A5 .nested_img5 {
	position: absolute;
	top: 396px;
	left: 594px;
	width: 50px;
	height: 50px;
	z-index: 1;
}

.top_sec .box_container .A3 .nested_img3 {
	left: 688px;
}

.top_sec .box_container .A4 .nested_img4 {
	left: 782px;
}
.top_sec .box_container .A5 {
	width: 89px;
}

.top_sec .box_container .A5 .nested_img5 {
	left: 876px;
}

.top_sec .box_container .A1 .img1,
.top_sec .box_container .A2 .img2,
.top_sec .box_container .A3 .img3,
.top_sec .box_container .A4 .img4,
.top_sec .box_container .A5 .img5 {
	width: 84px;
	height: 107px;
}

.top_sec .photo_upload {
	display: flex;
	justify-content: center;
	/* height: 65px; */
	padding: 50px 0;
	align-self: center;
}

.top_sec .photo_upload .photo_container {
/*	width: 600px;*/
	display: flex;
	justify-content: center;
}
.top_sec .photo_upload .photo_container .upload_file {
	width: 234px;
	height: 65px;
	position: absolute;
	cursor: pointer;
	z-index: 1000;
}

.top_sec .photo_upload .photo_container .done_photo_button {
	display: none;
	margin-left: 85px;
}


.top_sec .main_sec {
	display: flex;
	justify-content: space-around;
}

.top_sec .main_sec .details_photo_container {
	display: flex;
	justify-content: space-between;
	margin-right: 20px;
}

.top_sec .main_sec .details_photo_container .photo_container {
	margin-top: 80px;
	margin-right: 70px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-start;
	width: 176px;
}

.top_sec .main_sec .details_photo_container .photo_container img:not(.img0) {
	margin-top: 26px;
}

.top_sec .main_sec .details_photo_container .details {
	margin-top: 40px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
/*	width: 435px;*/
}

.top_sec .main_sec .details_photo_container .details p.delivery {
	margin-top: 1em;
	margin-bottom: 1em;
}

.top_sec .main_sec .details .delivery input[type="radio" i] {
	margin: 3px 3px 0px 8px;
}

.top_sec .main_sec .details .delivery label {
	margin-bottom: 0px;
}

.top_sec .main_sec .input-controls {
	font-size: 18px;
	width: 180px;
	border: 2px solid #02aaea;
	border-radius: 5px;
	padding: 10px;
	outline-color: #69def9;
}

.top_sec .main_sec .details_photo_container .details .text:not(#text0) {
	height: 134px;
}
.top_sec .main_sec .details_photo_container .details .text {
	margin-top: 30px;
	display: flex;
	align-items: center;
}
.top_sec .main_sec .details_photo_container .details .text .country {
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	font-size: 16px;
}

.top_sec .main_sec .details_photo_container .details .text .cart {
	margin-left: 150px;
}

.top_sec .main_sec .details_photo_container .details .text .cart:not(#cart0) {
	margin-top: 60px;
}
.top_sec .main_sec .details_photo_container .details .text .quantity,
.top_sec .main_sec .details_photo_container .details .text .value {
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	font-size: 23px;
}

.top_sec button.photo_img, .top_sec .done button, .top_sec .home_button .return_home, .top_sec .main_sec .details_photo_container .details .text .backbutton, .top_sec .main_sec .details_photo_container .details .text .frontbutton {
	display: block;
	color: white;
	background-color: #02aaea;
	height: 50px;
	font-size: 20px;
	border-radius: 5px;
	border: none;
	margin-top: 40px;
	padding: 0 40px;
	text-align: center;
	outline: none;
	white-space: nowrap;
	cursor: pointer;
}

.top_sec .main_sec .details_photo_container .details .text .backbutton {
	outline: none;
	height: 45px;
	margin-left: 20px;
	margin-top: 0;
	padding: 0 10px;
	border-radius: 5px 0 0 5px;
}

.top_sec .main_sec .details_photo_container .details .text .backbutton:not(#backbutton0) {
	margin-top: 98px;
}
.top_sec .main_sec .details_photo_container .details .text .frontbutton {
	outline: none;
	height: 45px;
	margin-top: 0;
	padding: 0 10px;
	border-radius: 0 5px 5px 0;
}

.top_sec .main_sec .details_photo_container .details .text .frontbutton:not(#frontbutton0) {
	margin-top: 98px;
}
.top_sec .main_sec .details_photo_container .details .text #frontbutton {
	margin: 0;
}
.top_sec .main_sec .details_photo_container .details .text .country:not(#country0) {
	margin-top: 98px;
	margin-bottom: 0;
	height: 36px;
}
.top_sec .main_sec .details_photo_container .details .text #country0 {
	margin: 0;
}
.top_sec .main_sec .details_photo_container .details .text .quantity:not(#quantity0),
.top_sec .main_sec .details_photo_container .details .text .value:not(#value0) {
	margin-top: 98px;
	margin-bottom: 0;
}
.top_sec .main_sec .details_photo_container .details .text #quantity0 {
	margin: 0;
	border: 2px solid #02aaea;
	padding: 8px 7px;
	height: 25px;
}
.top_sec .main_sec .details_photo_container .details .text #value0 {
	margin: 5px 0 0 20px;
}
.top_sec .main_sec .details_photo_container .details .Total {
	float: left;
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	border-top: 1px solid #3f3f3f;
}
/*.top_sec .main_sec .details_photo_container .details .Total .line {
	margin-top: 30px;
}*/

.top_sec .main_sec .details_photo_container .details .Total .subtotal_amount,
.top_sec .main_sec .details_photo_container .details .Total .shipping_amount {
	margin-top: 26px;
	margin-bottom: 0px;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	font-size: 18px;
}

.top_sec .main_sec .details_photo_container .details .Total .total_amount {
	margin-top: 36px;
	margin-bottom: 0;
	font-family: 'Roboto', sans-serif;
	/* font-weight: 400; */
	/* font-size: 26px; */
}

.top_sec .main_sec input#coupon {
	border-radius: 5px 0 0 5px;
	height: 45px;
}

.top_sec .main_sec input#apply-coupon {
	color: white;
	background-color: #02aaea;
	height: 45px;
	font-size: 20px;
	border-radius: 0 5px 5px 0;
	border: none;
	padding: 0 20px 2px;
	text-align: center;
	outline: none;
	white-space: nowrap;
	cursor: pointer;
}

.top_sec .main_sec .shipping_info_container {
	margin-top: 50px;
	margin-right: 176px;
	display: flex;
	justify-content: flex-end;
}

.top_sec .main_sec .shipping_info_container .shipping_info {
	width: 417px;
	/* height: 300px; */
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 0;
}

.top_sec .main_sec .shipping_info_container .shipping_info .header {
	margin: 0;
	margin-bottom: 10px;
	font-family: 'Roboto', sans-serif;
	font-weight: 400;
	font-size: 28px;
}

.top_sec .main_sec .shipping_info_container .shipping_info .row1,
.top_sec .main_sec .shipping_info_container .shipping_info .row2,
.top_sec .main_sec .shipping_info_container .shipping_info .row3,
.top_sec .main_sec .shipping_info_container .shipping_info .row4,
.top_sec .main_sec .shipping_info_container .shipping_info .row5,
.top_sec .main_sec .shipping_info_container .shipping_info .row6 {
	display: flex;
	justify-content: space-between;
	margin-bottom: 5px;
}
.top_sec .main_sec .shipping_info_container .shipping_info .row1 .firstName,
.top_sec .main_sec .shipping_info_container .shipping_info .row1 .lastName,
.top_sec .main_sec .shipping_info_container .shipping_info .row2 .address,
.top_sec .main_sec .shipping_info_container .shipping_info .row3 .address,
.top_sec .main_sec .shipping_info_container .shipping_info .row4 .city,
.top_sec .main_sec .shipping_info_container .shipping_info .row4 .state,
.top_sec .main_sec .shipping_info_container .shipping_info .row4 .zipCode,
.top_sec .main_sec .shipping_info_container .shipping_info .row5 .emailAddress,
.top_sec .main_sec .shipping_info_container .shipping_info .row6 .phone {
	font-size: 18px;
	width: 180px;
	border: 2px solid #02aaea;
	border-radius: 5px;
	padding: 10px;
	outline-color: #69def9;
}
.top_sec .main_sec .shipping_info_container .shipping_info .row2 .address,
.top_sec .main_sec .shipping_info_container .shipping_info .row3 .address,
.top_sec .main_sec .shipping_info_container .shipping_info .row5 .emailAddress,
.top_sec .main_sec .shipping_info_container .shipping_info .row6 .phone {
	width: 417px;
}
.top_sec .main_sec .shipping_info_container .shipping_info .row4 .state {
	width: 50px;
}
.top_sec .main_sec .shipping_info_container .shipping_info .row4 .zipCode {
	width: 100px;
}

.top_sec .paypal-button\ paypal-button-number-0\ paypal-button-layout-horizontal\ paypal-button-shape-pill\ paypal-button-branding-branded\ paypal-button-number-single\ paypal-button-env-sandbox\ paypal-button-label-checkout\ paypal-button-color-gold\ paypal-button-logo-color-blue {
	width: 200px;
}

.top_sec .checkout_button {
	display: none;
	margin-top: 50px;
/*	margin-bottom: 40px;*/
	width: 100%;
	justify-content: center;
}

.top_sec .photo_upload {
	display: flex;
	justify-content: center;
	padding-top: 50px;
	align-self: center;
	padding-bottom: 22px;
}
.top_sec .photo_upload .photo_img {
	outline: none;
	border-style: none;
	outline-width: 0;
}


.top_sec .Thanks {
	display: flex;
	justify-content: center;
}

.top_sec .Thanks .header_top {
	font-size: 48px;
	margin-top: 60px;
}

.top_sec .paragraph_container {
	margin-top: 40px;
}
.top_sec .paragraph_container p {
	text-align: center;
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	font-size: 24px;
}


.lowerTop {
	width: inherit;
	display: block;
	background: #fff;
	padding-bottom: 0;
}
.lowerTop .bottom_bar .bottom_left {
	padding-left: 60px;
	color: #000;
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	font-size: 20px;
}
.lowerTop .bottom_bar .bottom_left p {
	vertical-align: top;
	margin-left: 20px;
	float: left;
}
.lowerTop .bottom_bar .bottom_right {
	padding-right: 60px;
	color: #000;
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	font-size: 20px;
}
.lowerTop .bottom_bar .bottom_right p {
	vertical-align: top;
	margin-right: 20px;
	float: right;
}

.lowerTop .box_steps {
	padding-top: 30px;
	text-align: center;
	width: 100%;
}

.lowerTop .box_steps h3 {
	font-size: 36px;
	color: #666;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	font-weight: bold;
}

.lowerTop .box_steps .step1, .lowerTop .box_steps .step2, .lowerTop .box_steps .step3 {
	display: inline-block;
	margin: 0 30px 60px;
	border: 2px solid #69def9;
	border-radius: 5px;
	height: 400px;
	width: 260px;
}

.lowerTop .box_steps .step1 {
	background: url(../images/photo_step_1.png)
}

.lowerTop .box_steps .step2 {
	background: url(../images/photo_step_2.png)
}

.lowerTop .box_steps .step3 {
	background: url(../images/photo_step_3.png)
}

.lowerTop .box_steps h4 {
	font-size: 36px;
	color: #69def9;
	font-weight: normal;
	margin: 30px 0 0;
}

.lowerTop .box_steps p {
	font-size: 18px;
	color: #666;
	margin: 10px 0 0;
}

.lowerTop .box_steps p span {
	font-weight: bold;
	margin-top: 220px;
	display: block;
}

.middle_sec {
	width: 100%;
	background-color: #85d1ff;
	height: auto;
	padding-bottom: 85px;
}
.home .middle_sec {
	padding-top: 80px;
	padding-bottom: 80px;
	display: flex;
	justify-content: space-evenly;
	flex-grow: 1;
	background: url('../images/Background_2.png');
	background-repeat: no-repeat;
	background-size: cover;
	width: inherit;
}

.middle_sec .money_logo,
.middle_sec .time_logo,
.middle_sec .travel_logo {
	color: #fff;
	padding-top: 105px;
	text-align: center;
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	font-size: 22px;
}
.middle_sec .money_logo {
	background: url('../images/SaveMoneyIcon.png') top center no-repeat;
}
.middle_sec .time_logo {
	background: url('../images/SaveTimeIcon.png') top center no-repeat;
}
.middle_sec .travel_logo {
	background: url('../images/TravelAnywhereIcon.png') top center no-repeat;
}

	
.middle_sec .bubbles {
	display: flex;
	width: 100%;
	justify-content: space-between;
	padding-top: 50px;
	height: 361px;
	flex-wrap: wrap;
}

.middle_sec .bubbles ul {
	display: flex;
	justify-content: space-between;
	width: 100%;
/*	height: 163px;*/
	margin: 0;
	padding: 0;
	list-style: none;
}
.middle_sec .bubbles .bubbles_bot {
	margin-top: 35px;
}

.middle_sec .bubbles ul li {
	height: 163px;
}
.middle_sec .bubbles .bubbles_top .A1,
.middle_sec .bubbles .bubbles_bot .B1 {
	margin-left: 155px;
}
.middle_sec .bubbles .bubbles_top .A4,
.middle_sec .bubbles .bubbles_bot .B4 {
	margin-right: 155px;
}

.middle_sec .bubbles ul li .text_container {
	height: 163px;
	width: 163px;
	top: -167px;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.middle_sec .bubbles ul li .text_container .inside_header {
	width: 163px;
	position: relative;
	flex-wrap: wrap;
	font-size: 19px;
	font-family: 'Roboto', sans-serif;
	font-weight: 500;
	text-align: center;
	color: #29abe2;
}

.middle_sec .bubbles ul li .text_container .inside_text {
	width: 163px;
	position: relative;
	flex-wrap: wrap;
	font-family: 'Roboto', sans-serif;
	font-size: 19px;
	font-weight: 300;
	text-align: center;
	color: #666;
}


.flags {
	width: 100%;
}

.bottom_sec {
	width: inherit;
	display: flex;
	flex-direction: column;
	background: #02aaea;
	padding-bottom: 1px;
	height: 120px;
}

.bottom_sec .Follow_us {
	padding-top: 70px;
	width: 100%;
}
.bottom_sec .Follow_us p {
	color: #999;
	margin: 0;
	text-align: center;
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	font-size: 30px;
}

.bottom_sec .media_links a {
	margin-left: 15px;
}
.bottom_sec .media_links a img {
	outline: none;
	border-style: none;
	outline-width: 0;
	vertical-align: initial;
}

.bottom_sec .bottom_bar {
	padding: 10px 0px;
}

.bottom_sec .bottom_bar .bottom_left {
	padding-left: 60px;
	color: #999;
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	font-size: 20px;
}
.bottom_sec .bottom_bar .bottom_left p {
	vertical-align: top;
	margin-left: 20px;
	margin-top: 1em;
	margin-bottom: 0px;
	float: left;
}
.bottom_sec .bottom_bar .bottom_left a {
	color: #fff;
	text-decoration: none;
}

.bottom_sec .bottom_bar .bottom_right {
	padding-right: 60px;
	color: #999;
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	font-size: 20px;
}
.bottom_sec .bottom_bar .bottom_right p {
	vertical-align: top;
	margin-right: 20px;
	margin-top: 1em;
	margin-bottom: 0px;
	float: right;
}

.bottom_sec .copyright {
	text-align: center;
	color: #fff;
	font-family: 'Roboto', sans-serif;
	clear: both;
	font-size: 12px;
	margin-top: auto;
	margin-right: 0px;
}

.bottom_sec .copyright p {
	margin: auto 0px 1em 0px;
}

.input-file {
	display: none;
}

.back-btn{
	background: #04A1DC;
	color: #fafafa;
	border-radius: 12px;
	width: 40%;
}

@media only screen and (max-width: 1400px) {
	.top_sec .main_sec {
		justify-content: space-around;
	}

	.top_sec .main_sec .details_photo_container .photo_container {
		width: 100px;
		margin-right: 20px;
	}

	.top_sec .main_sec .details_photo_container .details .text .cart {
		margin-left: 100px;
	}

	.top_sec .main_sec .shipping_info_container {
		margin-right: 0px;
		justify-content: center;
	}
}

.expanding-container {
	margin-top: 76px;
	min-height: calc(100vh - 196px);
	box-sizing: border-box;
}

@media only screen and (max-width: 1000px) {
	.top_sec .box_steps .step1,
	.top_sec .box_steps .step2 {
		margin-right: 24px;
	}
	.top_sec .box_steps img {
		width: 100%;
	/*	height: 321px;*/
	}

	.top_sec .bubbles ul li .img {
		height: 97px;
		width: 97px;
	}

	.top_sec .bubbles ul li .text_container {
		height: 97px;
		width: 97px;
		top: -101px;
	}

	.top_sec .bubbles ul li .text_container .inside_header {
		width: 90px;
		font-size: 12px;
	}

	.top_sec .bubbles ul li .text_container .inside_text {
		width: 90px;
		font-size: 10px;
	}

	.top_sec .bubbles .phone_photo {
		height: 174px;
		width: 148px;
	}

	.top_sec .avatar {
		padding-top: 40px;
	}

	.top_sec .box_container {
		margin-top: 32px;
	}

	.top_sec .photo_upload {
		padding: 30px 0;
	}

	.top_sec .main_sec {
		justify-content: center;
		flex-direction: column;
	}

	.top_sec .main_sec .details_photo_container {
		width: 100%;
		justify-content: center;
	}

	.top_sec .main_sec .details_photo_container .photo_container {
		margin-top: 60px;
	}

	.top_sec .main_sec .details_photo_container .details {
		margin-top: 60px;
		margin-left: 70px;
	}

	.middle_sec .bubbles .bubbles_top,
	.middle_sec .bubbles .bubbles_bot {
		justify-content: space-around;
	}

	.middle_sec .bubbles .bubbles_top .A1,
	.middle_sec .bubbles .bubbles_top .A4,
	.middle_sec .bubbles .bubbles_bot .B1,
	.middle_sec .bubbles .bubbles_bot .B4 {
		margin: 0;
	}
}

@media screen and (max-width: 767px) {
/*	.nav_bar {
		height: 53px;
	}*/
	.navbar-collapse {
		background-color: rgba(255,255,255,0.96);
	}

	.top_sec {
		padding: 0 40px 40px;
	}

	.home .top_sec .text .header_top,
	.middle_sec .text .header_top {
		margin-top: 40px;
	}

	.top_sec .drop_down_container {
	/*	margin-top: 40px;*/
	}

	.top_sec button.photo_img,
	.top_sec .done button,
	.top_sec .home_button .return_home {
		margin-top: 30px;
	}

	.top_sec .photo_button {
		margin-top: 32px;
	/*	padding-bottom: 81px;*/
	}

	.lowerTop .bottom_bar .bottom_left {
		padding-left: 35px;
	}

	.lowerTop .bottom_bar .bottom_right {
	  padding-right: 35px;
	}

	.home .middle_sec {
		padding-top: 80px;
		padding-bottom: 72px;
	}

	/* .bottom_sec {
		padding-bottom: 25px;
	} */

	.bottom_sec .Follow_us {
		padding-top: 63px;
	}

/*	.bottom_sec .media_links {
		padding-top: 73px;
	}*/

	.bottom_sec .bottom_bar .bottom_left {
		padding-left: 35px;
	}

	.bottom_sec .bottom_bar .bottom_right {
		padding-right: 35px;
	}
}

@media only screen and (max-width: 740px) {
	.top_sec .main_sec .details_photo_container {
		width: 100%;
		justify-content: center;
	}
}

@media only screen and (max-width: 720px) {
	.top_sec .box_steps {
		flex-direction: column;
		align-items: center;
		padding-top: 20px;
	}

	.top_sec .box_steps .step1,
	.top_sec .box_steps .step2 {
		margin-bottom: 20px;
		margin-right: 0;

	}

	.top_sec .avatar .avatar_pic .img0 {
		width: 95px;
		height: 119px;
	}

	.top_sec .box_container .nested_box_container .B1,
	.top_sec .box_container .nested_box_container .B2,
	.top_sec .box_container .nested_box_container .B3,
	.top_sec .box_container .nested_box_container .B4,
	.top_sec .box_container .nested_box_container .B5 {
		width: 55px;
	}
	.top_sec .box_container .nested_box_container .B1 {
		margin-right: 5px;
	}

	.top_sec .box_container .A1,
	.top_sec .box_container .A2,
	.top_sec .box_container .A3,
	.top_sec .box_container .A4,
	.top_sec .box_container .A1 {
		width: 55px;
	}
	.top_sec .box_container .A1 .img1,
	.top_sec .box_container .A2 .img2,
	.top_sec .box_container .A3 .img3,
	.top_sec .box_container .A4 .img4,
	.top_sec .box_container .A5 .img5 {
		width: 50px;
		height: 62px;
	}

	.top_sec .photo_upload .photo_container .upload_file {
		width: 100px;
		height: 35px;
	}
	.top_sec .photo_upload .photo_container .upload_photo_button {
		width: 136px;
		height: 45px;
	}
	.top_sec .photo_upload .photo_container .done_photo_button {
		width: 136px;
		height: 45px;
		margin-left: 30px;
	}

	.top_sec .step2_checkpoint {
		/* display: none; */
	}

	.top_sec .Thanks .header_top {
		font-size: 36px;
		margin-top: 40px;
	}

	.top_sec .paragraph_container {
		margin-top: 20px;
	}
	.top_sec .paragraph_container p {
		font-size: 18px;
	}

	.middle_sec {
		padding: 0 60px 60px;
	}

	.middle_sec .bubbles {
		justify-content: center;
		flex-direction: column;
		padding-top: 20px;
		height: 750px;
	}

	.middle_sec .bubbles ul {
		justify-content: center;
		flex-direction: column;
		align-items: center;
		flex-wrap: wrap;
		height: 350px;
	}
	.middle_sec .bubbles .bubbles_bot {
		margin-top: 20px;
	}
	.middle_sec .bubbles ul li {
		height: 119px;
	}

	.middle_sec .bubbles .bubbles_top .A1,
	.middle_sec .bubbles .bubbles_top .A3,
	.middle_sec .bubbles .bubbles_bot .B1,
	.middle_sec .bubbles .bubbles_bot .B3 {
		margin-bottom: 20px;
	}

	.middle_sec .bubbles ul li .img {
		height: 119px;
		width: 119px;
	}

	.middle_sec .bubbles ul li .text_container {
		height: 119px;
		width: 119px;
		top: -123px;
	}
	.middle_sec .bubbles ul li .text_container .inside_header {
		width: 119px;
		font-size: 16px;
	}
	.middle_sec .bubbles ul li .text_container .inside_text {
		width: 119px;
		font-size: 14px;
	}
}

@media screen and (max-width: 660px) {
	.top_sec .bubbles {
		justify-content: center;
		flex-direction: column;
	}

	.top_sec .bubbles ul {
		margin-left: 0;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 267px;
	}
	.top_sec .bubbles .bubbles_right {
		padding-top: 17px;
	}

	.top_sec .bubbles ul .A1,
	.top_sec .bubbles ul .A3,
	.top_sec .bubbles ul .B1,
	.top_sec .bubbles ul .B3 {
		margin-bottom: 20px;
		width: 123px;
	}

	.top_sec .bubbles ul li .img {
		height: 123px;
		width: 123px;
	}

	.top_sec .bubbles ul li .text_container {
		height: 119px;
		width: 119px;
	}
	.top_sec .bubbles ul .A3 .text_container {
		top: -85px;
		left: 2px;
	}
	.top_sec .bubbles ul .A4 .text_container {
		top: -85px;
	}
	.top_sec .bubbles ul .B2 .text_container {
		top: -85px;
		left: 2px;
	}
	.top_sec .bubbles ul .B3 .text_container {
		top: -95px;
	}

	.top_sec .bubbles ul li .text_container .inside_header {
		font-size: 16px;
		width: 110px;
	}

	.top_sec .bubbles ul li .text_container .inside_text {
		height: 123px;
		width: 123px;
		font-size: 14px;
		width: 110px;
	}
	.top_sec .bubbles ul .A1 .text_container .inside_text,
	.top_sec .bubbles ul .A2 .text_container .inside_text,
	.top_sec .bubbles ul .A3 .text_container .inside_text,
	.top_sec .bubbles ul .B1 .text_container .inside_text,
	.top_sec .bubbles ul .B4 .text_container .inside_text {
		left: 2px;
	}

	.top_sec .bubbles .phone_photo {
		padding: 0;
		display: none;
	}

	.top_sec .step1_checkpoint {
		display: none;
	}

	.top_sec .main_sec .details_photo_container .photo_container {
		margin-top: 40px;
		width: 50px;
	}

	.top_sec .main_sec .input-controls {
		font-size: 14px;
		width: 132px;
	}

	.top_sec .main_sec .details_photo_container .photo_container img:not(.img0) {
		margin-top: 69px;
	}

	.top_sec .main_sec .details_photo_container .photo_container img {
		width: 50px;
		height: 62px;
	}

	.top_sec .main_sec .details_photo_container .details {
		margin-top: 40px;
		margin-left: 10px;
		width: 250px;
	}

	.top_sec .main_sec .details_photo_container .details .text .country {
		font-size: 12px;
	}

	.top_sec .main_sec .details_photo_container .details .text .cart {
		margin-left: 40px;
	}

	.top_sec .main_sec .details_photo_container .details .text .quantity,
	.top_sec .main_sec .details_photo_container .details .text .value {
		font-size: 13px;
	}

	.top_sec .main_sec .details_photo_container .details .text .backbutton {
		font-size: 13px;
		height: 40px;
	}

	.top_sec .main_sec .details_photo_container .details .text .frontbutton {
		font-size: 13px;
		height: 40px;
	}

	.top_sec .main_sec .details_photo_container .details .text #quantity0 {
		padding: 11px 7px;
		height: 14px;
	}

	.top_sec .main_sec .details_photo_container .details .Total {
		margin-top: 10px;
	/*	width: 250px;
		align-self: flex-start;*/
	}

	.top_sec .main_sec .details_photo_container .details .Total .subtotal_amount,
	.top_sec .main_sec .details_photo_container .details .Total .shipping_amount {
		font-size: 13px;
		margin-top: 28px;
		align-self: flex-start;
	}

	.top_sec .main_sec .details_photo_container .details .Total .shipping_amount{
		margin-bottom: 13px;
		display: flex;
	}

	.top_sec .main_sec .details_photo_container .details .Total .total_amount {
		font-size: 20px;
		margin-top: 15px;
		align-self: flex-start;
	}

	.top_sec .main_sec input#coupon {
		width: 152px;
		height: 40px;
	}

	.top_sec .main_sec input#apply-coupon {
		font-size: 14px;
		height: 40px;
		padding-top: 2px;
	}

	.top_sec .main_sec .shipping_info_container {
		margin-top: 40px;
	}

	.top_sec .main_sec .shipping_info_container .shipping_info {
		/* height: 200px; */
		width: 320px;
		justify-content: center;
	}

	.top_sec .main_sec .shipping_info_container .shipping_info .header {
		font-size: 20px;
	}

	.top_sec .main_sec .cntry-slct,
	.top_sec .main_sec .shipping_info_container .shipping_info .row1 .firstName,
	.top_sec .main_sec .shipping_info_container .shipping_info .row1 .lastName,
	.top_sec .main_sec .shipping_info_container .shipping_info .row2 .address,
	.top_sec .main_sec .shipping_info_container .shipping_info .row3 .address,
	.top_sec .main_sec .shipping_info_container .shipping_info .row4 .city,
	.top_sec .main_sec .shipping_info_container .shipping_info .row4 .state,
	.top_sec .main_sec .shipping_info_container .shipping_info .row4 .zipCode,
	.top_sec .main_sec .shipping_info_container .shipping_info .row5 .emailAddress,
	.top_sec .main_sec .shipping_info_container .shipping_info .row6 .phone {
		font-size: 14px;
    width: 132px;
    margin-top: 10px;
	}
	.top_sec .main_sec .shipping_info_container .shipping_info .row2 .address,
	.top_sec .main_sec .shipping_info_container .shipping_info .row3 .address,
	.top_sec .main_sec .shipping_info_container .shipping_info .row5 .emailAddress,
	.top_sec .main_sec .shipping_info_container .shipping_info .row6 .phone {
		width: 320px;
	}
	.top_sec .main_sec .shipping_info_container .shipping_info .row4 .state {
		width: 35px;
	}
	.top_sec .main_sec .shipping_info_container .shipping_info .row4 .zipCode {
		width: 65px;
	}

	.top_sec .checkout_button {
		margin-bottom: 40px;
	}

	.top_sec .checkout_button .checkout {
		width: 27px;
		height: 45px;
	}

	.top_sec .step3_checkpoint {
		display: none;
	}

	.top_sec .done button {
		margin-top: 70px;
	}
}

@media screen and (max-width: 480px) {
	.navbar {
		height: 56px;
	}

	.navbar .navbar-brand img {
		width: 58px;
	/*	height: 32px;*/
	}

	.navbar .left_container span {
		font-size: 19px;
		margin-left: 11px;
	}

	.navbar .navbar-collapse {
		font-size: 18px;
	}

	.nav_bar .right_container .hamburgerbut {
		margin-right: 18px;
		margin-top: 18px;
		width: 30px;
		height: 20px;
	}

	.nav_bar .right_container .hamburgerbut .hamimg {
	/*	background: url(images/Hambuger_ButtonMobile.png) no-repeat;*/
		width: 30px;
		height: 20px;
	}

	.nav_bar .right_container .hamburgerbut .scrollable-menu-ham option {
		font-size: 18px;
	/*	font-weight: 300;*/
	}

	.nav_bar .right_container .hamburgerbut .show {
		width: 150px;
	}

	.top_sec {
		margin-top: 56px;
	}

	h1,
	.top_sec .text .header_top {
		margin-top: 20px;
		font-size: 24px;
	}
	.home .top_sec .text .header_top,
	.middle_sec .text .header_top {
	/*	font-weight: 400;*/
		font-size: 30px;
		margin-top: 20px;
	}

	.top_sec .text .header_line {
		padding-top: 16px;
	}

	/* .top_sec .text .header_bottom {
		padding-top: 20px;
		font-size: 20px;
	} */
	/* .home .top_sec .text .header_bottom,
	.middle_sec .text .header_bottom {
		font-size: 24px;
		padding-top: 15px;
	} */

	.top_sec .drop_down_container {
		margin-top: 20px;
	}

	.top_sec .drop_down_container .drop_down_img {
		width: 100%;
	/*	height: 25px;*/
	}

/*	.top_sec .drop_down_container .scrollable-menu {
		width: 100%;
		margin-top: 25px;
		z-index: 10;
	}
	.top_sec .drop_down_container .scrollable-menu option {
		font-size: 12px;
		font-weight: 300;
	}

	.top_sec button.photo_img {
		width: 100%;
		height: 25px;
	}*/

	.top_sec .photo_button {
		margin-top: 22px;
	/*	padding-bottom: 56px;*/
	}

	.lowerTop .bottom_bar .bottom_left {
		padding-left: 5px;
		font-size: 15px;
	}

	.lowerTop .bottom_bar .bottom_right {
		padding-right: 5px;
		font-size: 15px;
	}

	.home .middle_sec {
		padding-top: 80px;
		padding-bottom: 72px;
		flex-direction: column;
		align-items: center;
	}

	.middle_sec .money_logo,
	.middle_sec .time_logo {
		margin-bottom: 46px;
	}

	.bottom_sec .Follow_us {
		padding-top: 41px;
		font-size: 18px;
	}

	.bottom_sec .bottom_bar .bottom_left {
		padding-left: 5px;
		font-size: 15px;
	}

	.bottom_sec .bottom_bar .bottom_right {
		padding-right: 5px;
		font-size: 15px;
	}

	.expanding-container {
		margin-top: 56px;
		min-height: calc(100vh - 176px);
	}

	.back-btn {
		width: 80%;
	}
}

@media screen and (max-width: 370px) {
	.navbar .left_container span {
		font-size: 14px;
	}

	.top_sec .text .header_bottom {
		font-size: 16px;
	}
}

.countries-slct{
    color: #fff;
}

.countries-slct option{
    color: #666;
}

.done-btn{
    display: none;
	cursor: pointer;
	margin-top: 12px;
}

.rmv-btn{
    position: absolute;
    background-color: red;
    border-radius: 12px;
    color: #fff;
    font-size: 18px;
    line-height: 18px;
    padding: 3px 8px 6px 8px;
    margin: 0px !important;
    font-weight: bold;
    cursor: pointer;
}

.img-border{
    border: 3px solid #dddddd;
    border-radius: 10px;
}

.top_sec .main_sec .details_photo_container .details .text:not(#text0) {
    height: 134px;
}

.display-paypal{
    display: block;
}

.hide-paypal{
    display: none;
}
/*# sourceMappingURL=index.css.map*/


/* Absolute Center Spinner */
.loading {
	position: fixed;
	z-index: 999;
	overflow: show;
	margin: auto;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	width: 50px;
	height: 50px;
  }
  
  /* Transparent Overlay */
  .loading:before {
	content: '';
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255,255,255,1);
  }
  
  /* :not(:required) hides these rules from IE9 and below */
  .loading:not(:required) {
	/* hide "loading..." text */
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
  }
  
  .loading:not(:required):after {
	content: '';
	display: block;
	font-size: 10px;
	width: 50px;
	height: 50px;
	margin-top: -0.5em;
  
	border: 15px solid rgba(33, 150, 243, 1.0);
	border-radius: 100%;
	border-bottom-color: transparent;
	-webkit-animation: spinner 1s linear 0s infinite;
	animation: spinner 1s linear 0s infinite;
  
  
  }
  
  /* Animation */
  
  @-webkit-keyframes spinner {
	0% {
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -ms-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  -moz-transform: rotate(360deg);
	  -ms-transform: rotate(360deg);
	  -o-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }
  @-moz-keyframes spinner {
	0% {
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -ms-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  -moz-transform: rotate(360deg);
	  -ms-transform: rotate(360deg);
	  -o-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }
  @-o-keyframes spinner {
	0% {
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -ms-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  -moz-transform: rotate(360deg);
	  -ms-transform: rotate(360deg);
	  -o-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }
  @keyframes spinner {
	0% {
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -ms-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  -moz-transform: rotate(360deg);
	  -ms-transform: rotate(360deg);
	  -o-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }

/* *{
	box-sizing: inherit;
} */

/* .main_sec{
	padding: 12px;
} */

.border-box {
	box-sizing: border-box !important;
}